import styled from 'styled-components'
import React from 'react'
import { StyledContainer } from '../pages'
import { success } from './variables'
export const Breeam: React.FC<{ data: any }> = ({ data }) => {
  return (
    <StyledBreeam id={'BREEAM'} className={'BREEAM'}>
      <StyledHeaderContainer>
        <StyledHeader>{data.headerText}</StyledHeader>
        <StyledTitle>{data.Title}</StyledTitle>
      </StyledHeaderContainer>
      <StyledContainer>
        <Text>{data.Text1}</Text>
        <br />
        <Text>{data.Text2}</Text>
        <br />
        <Text>{data.Text3}</Text>
        <br />
        <TextBold>{data.List1Title}</TextBold>
        <ul>
          <li>{data.List1Element1}</li>
          <li>{data.List1Element2}</li>
        </ul>
        <TextBold>{data.List2Title}</TextBold>
        <ul>
          <li>{data.List2Element1}</li>
          <li>{data.List2Element2}</li>
          <li>{data.List2Element3}</li>
          <li>{data.List2Element4}</li>
        </ul>
        <br />
        <Text>{data.BottomListText}</Text>
        <ul>
          <br />
          <li>{data.BottomListElement1}</li>
          <li>{data.BottomListElement2}</li>
          <li>{data.BottomListElement3}</li>
          <li>{data.BottomListElement4}</li>
          <li>{data.BottomListElement5}</li>
          <li>{data.BottomListElement6}</li>
        </ul>
      </StyledContainer>
    </StyledBreeam>
  )
}

const StyledBreeam = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px 0;
  justify-content: center;
  background-color: rgb(0, 45, 115);
  color: white;
  border-bottom: 1px solid #0cacc1;
`

const Text = styled.p`
  font-size: 15px;
`

const TextBold = styled.p`
  font-size: 15px;
  font-weight: bold;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`

export const StyledHeader = styled.h2`
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;
  color: ${success};
  font-size: 18px;
  line-height: 3.39;
  letter-spacing: 5.81px;
  text-transform: uppercase;
  margin: 15px;
`

const StyledTitle = styled.h3`
  font-size: 45px;
  font-weight: bold;
  line-height: 1.36;
  letter-spacing: 0.65px;
  text-align: center;
  margin-top: 0px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 35px;
  }
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`
