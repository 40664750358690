import React from "react"

const Access = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 39.3 33.9"
    >
      <title>Zasób 40</title>
      <g>
        <path
          d="M18.8,3.7H14c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h4.8c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7h-8.7
            V4.5h1.3c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4h-1.3V1.6C10.1,0.7,9.3,0,8.4,0H1.6C0.7,0,0,0.7,0,1.6v30.6c0,0.9,0.7,1.6,1.6,1.6
            h13.7c0.9,0,1.6-0.7,1.6-1.6v-0.5c0-0.9-0.7-1.6-1.6-1.6H10V11.4l3.8,4.2c0.5,0.5,1.1,0.8,1.8,0.8c1.4,0,2.5-1.1,2.5-2.5
            c0-0.6-0.2-1.2-0.7-1.7l-3.2-3.6h4.4c1.4,0,2.5-1.1,2.5-2.5C21.1,4.7,20.1,3.7,18.8,3.7z M0.8,1.6c0-0.5,0.4-0.8,0.8-0.8h6.8
            c0.5,0,0.8,0.4,0.8,0.8l0.1,28.5H0.8V1.6z M15.4,30.9c0.5,0,0.8,0.4,0.8,0.8v0.6c0,0.5-0.4,0.8-0.8,0.8H1.6c-0.5,0-0.8-0.4-0.8-0.8
            v-1.4H15.4z M17,12.8c0.6,0.7,0.6,1.7-0.1,2.4c-0.7,0.6-1.7,0.6-2.4-0.1l-4.4-4.9V8.7h3.2L17,12.8z"
        />
        <path
          d="M6.9,3.1H3.2C2.5,3.1,2,3.7,2,4.3V8c0,0.6,0.5,1.2,1.2,1.2h3.7c0.7,0,1.2-0.5,1.2-1.2V4.3
            C8.1,3.7,7.6,3.1,6.9,3.1z M7.3,8.1c0,0.2-0.2,0.4-0.4,0.4H3.2C3,8.4,2.8,8.3,2.8,8.1V4.3c0-0.2,0.2-0.4,0.4-0.4h3.7
            c0.2,0,0.4,0.2,0.4,0.4V8.1z"
        />
        <path
          d="M6.9,10.3H3.2c-0.6,0-1.2,0.5-1.2,1.2v0.6c0,0.7,0.5,1.2,1.2,1.2h3.7c0.7,0,1.2-0.5,1.2-1.2v-0.6
            C8.1,10.9,7.6,10.3,6.9,10.3z M7.3,12.2c0,0.2-0.2,0.4-0.4,0.4H3.2c-0.2,0-0.4-0.2-0.4-0.4v-0.6c0-0.2,0.2-0.4,0.4-0.4h3.7
            c0.2,0,0.4,0.2,0.4,0.4V12.2z"
        />
        <path
          d="M37.7,0h-3.3c-0.9,0-1.6,0.7-1.6,1.6v2.1h-7.3c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5h3.1l-3.2,3.6
            c-0.9,1-0.9,2.6,0.1,3.5c1,0.9,2.6,0.9,3.5-0.1l3.8-4.2v18.6H24c-0.9,0-1.6,0.7-1.6,1.6v0.5c0,0.9,0.7,1.6,1.6,1.6h13.7
            c0.9,0,1.6-0.7,1.6-1.6v-6.5c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v4.4h-5V1.6c0-0.5,0.4-0.8,0.8-0.8h3.3c0.5,0,0.8,0.4,0.8,0.8
            v20.2v0.3v0.3c0,0.2,0.2,0.5,0.4,0.5c0.2,0,0.5-0.2,0.5-0.5v-0.3v-0.3V1.6C39.3,0.7,38.6,0,37.7,0z M32.7,10.2L28.3,15
            c-0.6,0.7-1.7,0.7-2.4,0.1c-0.7-0.6-0.7-1.7-0.1-2.4l3.7-4.1h3.2V10.2z M32.7,7.9h-3.5h-3.8c-0.9,0-1.7-0.8-1.7-1.7
            c0-0.9,0.8-1.7,1.7-1.7h7.3V7.9z M38.5,31v1.3c0,0.5-0.4,0.8-0.8,0.8H23.9c-0.5,0-0.8-0.4-0.8-0.8v-0.5c0-0.5,0.4-0.8,0.8-0.8H38.5
            z"
        />
      </g>
    </svg>
  )
}

export default Access
