import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Navigation from './Navigation'
import NavLogo from './NavLogo'
import { fontColor } from './variables'
import MobileNavi from './MobileNavi'
import { StyledContainer } from '../pages/index'

interface HeaderProps {
  data: {
    description?: object
    location?: object
    area?: object
    standard?: object
    news?: object
    contact?: object
  }
  lang: string
  propertyName: string
  lngChangeHandler(): void
}

const Header: React.FC<HeaderProps> = ({ data, lang, lngChangeHandler, propertyName }) => {
  const [mobileDisplay, setMobileDisplay] = useState(window.innerWidth > 1024 ? false : true)

  useEffect(() => {
    window.addEventListener('resize', mobileMenuHandler)
    return () => {
      window.removeEventListener('resize', mobileMenuHandler)
    }
  }, [])

  const mobileMenuHandler = () => {
    window.innerWidth < 1024 ? setMobileDisplay(true) : setMobileDisplay(false)
  }

  const titles = []

  Object.keys(data).forEach(e => {
    titles.push(data[e][lang].sectionTitle)
  })
  titles.splice(4, 0, 'BREEAM')

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledHeaderContainer>
          <NavLogo propertyName={propertyName} />
          {mobileDisplay ? (
            <MobileNavi lang={lang} lngChangeHandler={lngChangeHandler} titles={titles} />
          ) : (
            <Navigation lang={lang} lngChangeHandler={lngChangeHandler} titles={titles} />
          )}
        </StyledHeaderContainer>
      </StyledContainer>
    </StyledHeader>
  )
}

export default React.memo(Header)

const StyledHeader = styled.header`
  height: 81px;
  width: 100%;
  background: ${fontColor};
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 10;
  position: fixed;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
