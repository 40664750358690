import React from "react"

const Optics = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 31.3">
      <g>
        <g>
          <path
            d="M38,15.5L38,15.5c-0.1-4.3-1.9-8.3-5.1-11.2c-3.2-3-7.6-4.5-12-4.2h-0.5v1.9c0,0.3,0.2,0.5,0.5,0.5l0,0
                c0.3,0,0.5-0.2,0.5-0.5V1c4-0.1,7.8,1.3,10.8,4c2.8,2.6,4.5,6.2,4.8,10h-1.8c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5H37
                c-0.1,3.9-1.8,7.6-4.6,10.2c-2.9,2.8-6.9,4.2-10.9,4.1V29c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v2.2h0.5c0.4,0,0.8,0,1.1,0
                c4,0,8-1.5,10.9-4.2C36.2,24,38,19.9,38,15.5L38,15.5L38,15.5z"
          />
          <path d="M17.5,7.2h-7.2c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h7.2C17.7,8.2,18,8,18,7.7C17.9,7.5,17.7,7.2,17.5,7.2z" />
          <path d="M4.4,8.2h3c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-3c-0.3,0-0.5,0.2-0.5,0.5C3.9,8,4.2,8.2,4.4,8.2z" />
          <path d="M17.5,23.3h-7.2c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h7.2c0.3,0,0.5-0.2,0.5-0.5C17.9,23.6,17.7,23.3,17.5,23.3z" />
          <path d="M7.5,23.3h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5S7.7,23.3,7.5,23.3z" />
          <path d="M17.5,15.3H5.2c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h12.2c0.3,0,0.5-0.2,0.5-0.5S17.7,15.3,17.5,15.3z" />
          <path d="M2.9,15.3H0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h2.4c0.3,0,0.5-0.2,0.5-0.5S3.1,15.3,2.9,15.3z" />
          <path
            d="M30.9,9.1C30.8,9,30.8,9,30.9,9.1c-0.3-0.3-0.5-0.3-0.7-0.1l-6.8,5.2c-0.9-0.3-1.9,0.1-2.3,1c-0.4,0.9,0.1,1.9,1,2.3
                s1.9-0.1,2.3-1c0.1-0.4,0.2-0.7,0.1-1.1l6.4-5.7C31,9.6,31,9.3,30.9,9.1z M22.7,16.8c-0.5,0-0.9-0.4-0.9-0.9
                c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C23.6,16.4,23.2,16.8,22.7,16.8z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Optics
