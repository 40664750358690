import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Close from './svg/Close'

const MobileNavi = ({ titles, lngChangeHandler, lang }) => {
  const [mobileMenu, setMobileMenu] = useState(true)

  let language
  lang === 'PL' ? (language = 'EN') : (language = 'PL')

  const showMenuHandler = () => {
    setMobileMenu(false)
  }
  const closeMenuHandler = () => {
    setMobileMenu(true)
  }

  const [mobileDisplay, setMobileDisplay] = useState(window.innerWidth > 786 ? false : true)
  // useref
  const scrollHandler = event => {
    event.preventDefault()
    let offset = document.getElementById(event.target.innerText.toUpperCase()).offsetTop - 85
    offset && window.scrollTo({ top: offset, left: 0, behavior: 'smooth' })
    setMobileMenu(true)
  }

  const naviLinks = titles.map((title, index) => {
    return (
      <li key={index}>
        <a onClick={scrollHandler} href={'/'}>
          {title}
        </a>
      </li>
    )
  })

  const mobileTranslate = mobileDisplay ? 'translateX(0)' : 'translateX(50%)'

  const menu = (
    <StyledMobileMenu
      style={{
        transform: mobileMenu ? 'translateX(100%)' : mobileTranslate,
      }}
    >
      <StyledUl>
        {naviLinks}
        <li>
          <StyledLngLink href="/" onClick={lngChangeHandler}>
            {language}
          </StyledLngLink>
        </li>
      </StyledUl>
      <div
        style={{
          position: 'fixed',
          right: mobileDisplay ? 'calc(0% + 25px)' : 'calc(50% + 45px)',
          top: '25px',
          cursor: 'pointer',
        }}
        onClick={closeMenuHandler}
      >
        <Close />
      </div>
    </StyledMobileMenu>
  )

  return (
    <div>
      <StyledHamburgerButton onClick={showMenuHandler}>
        <span></span>
        <span></span>
        <span></span>
      </StyledHamburgerButton>
      {menu}
    </div>
  )
}
export default MobileNavi

const StyledUl = styled.ul`
  margin-top: 55px;
  li {
    list-style-type: none;
    a {
      color: #fff;
      font-size: 20px;
      text-decoration: none;
      padding: 10px 0;
      display: block;
    }
  }
`
const StyledHamburgerButton = styled.div`
  height: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 30px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    right: 5px;
  }
  span {
    width: 40px;
    height: 3px;
    margin: 3px 0;
    background: #fff;
    border-radius: 4px;
  }
`
const StyledMobileMenu = styled.div`
  position: fixed;
  top: 0;
  /* bottom: 50%; */
  left: 0;
  right: 0;
  background: rgb(22, 32, 48);
  transition: 0.4s ease;
`

export const StyledLngLink = styled.a`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.23px;

  color: #ffffff;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease;
  padding: 10px 0;
`
