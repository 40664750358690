import React from "react";
import styled, { keyframes } from "styled-components";
import { StyledContainer } from "../pages/index";
import Img from "gatsby-image";
import { StyledSeparator } from "./Openinig";
import plan from "../data/images/floor.jpeg";
import { success, fontColor } from "./variables";
import Chevron from "./svg/Chevron";

interface AreaProps {
  data: {
    areaDesc: string;
    areaHeader: string;
    plansLabel: string;
    sectionTitle: string;
    sectionTitleInline: string;
  };
  planImg: {
    childImageSharp: {
      fluid: object;
    };
  };
}

const Area: React.FC<AreaProps> = ({ data, planImg }) => {
  return (
    <StyledSection id={`${data.sectionTitle.toUpperCase()}`}>
      <StyledContainer>
        <StyledFlexContainer>
          <StyledHeaderWrapper>
            <Styledh2>{data.sectionTitleInline}</Styledh2>
            <Styledh3>{data.areaHeader}</Styledh3>
          </StyledHeaderWrapper>
        </StyledFlexContainer>
        <StyledPlanImg src={plan} alt="floor plan" />
      </StyledContainer>
    </StyledSection>
  );
};

export default React.memo(Area);

const StyledSection = styled.section`
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }
`;
const Styledh2 = styled.h2`
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 5.81px;
  color: ${success};
  text-transform: uppercase;
  margin: 15px;
`;
const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const StyledHeaderWrapper = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Styledh3 = styled.h3`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.65px;
  color: ${fontColor};
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 35px;
  }
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`;

const StyledPlanImg = styled.img`
  width: 100%;
  margin-bottom: 40px;
`;
